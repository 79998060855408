/**
 * class to translate db fields like cancellation policies
 * wraps the api translations object
 * expects translations array from api
 */
import Translation from './Translation'

export default class Translations {
  /* global i18n */
  constructor (apiArray = null) {
    this.translations = []
    // parse standard api Array
    if (apiArray) this.initTranslations(apiArray)
  }

  initTranslations (apiTranslations) {
    apiTranslations.forEach((apiTranslation) => {
      /* global store */
      let language = store.state.languageObject.getLanguageByKey(apiTranslation.translationLanguage.name)
      let translation = new Translation(apiTranslation.name, apiTranslation.short, apiTranslation.description, language)

      this.translations.push(translation)
    })
  }

  // add a new translation to the translations array
  addTranslation (translationInstance) {
    this.translations.push(translationInstance)
  }

  // returns translation instance in current language of users settings
  getCurrentTranslation () {
    // in case no translation is available, create a translation, indicating we're missing one
    if (!this.translations.length) {
      return new Translation(
        i18n.t('translations.missingTranslation'),
        i18n.t('translations.missingTranslationShort'),
        i18n.t('missingTranslationDescription'),
        'de'
      )
    }
    /* global store */
    const foundTranslation = this.translations.find((translation) => {
      return translation.getLanguage().getLanguageKey() === store.state.languageObject.getCurrentKeyLanguage()
    })
    // return found translation or fallback to the first translation
    return foundTranslation || this.translations[0]
  }
}
